.popup_image {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--bs-tooltip-bg);
}
.modalBG {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    background: rgba(19,57,43,0.54);


    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    font-size: 17px;
    width: 100%;
    height: 100%;

    background: transparent;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-content: space-around;
    grid-template-rows: 60px auto 50px;
}

.header {
    display: flex;
    justify-content: end;
    align-items: center;
}

.closeButton {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.body {
   display: flex;
    flex-direction: column;
    height: 100%;
}

.img_container {
    height: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mini {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
    margin-right: 10px;
    margin-left: 10px;
}

.side_button {
    background: #DCE6E9;
    border-radius: 100%;
    padding: 14px 10px;
    height: 15px;
    transition: 0.3s all ease;
    cursor: pointer;
}

.side_button:hover {
    cursor: pointer;
    background: rgba(19,57,43,0.54);
}

.left {
   transform: rotate(90deg);
    margin-left: 10px;
}
.right {
    transform: rotate(-90deg);
    margin-right: 10px;
}


.mini_image{
    width: 36px;
    height: 36px;
    border-radius: 100px;
    object-fit: contain;
    margin-right: 10px;
}

.counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    color: #16e878;
    font-size: 20px;
}
