.modal {
    height: 96%;
    background: rgba(19,57,43,0.54);
    position: absolute;
    font-size: 17px;
    top: 2%;
    right: 0;
    width: 450px;
    z-index: 1;
    background: var(--bg-secondary);
    border-radius: 5px;
}

.content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.closeIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.body {
    font-family: Ubuntu-R;
    font-size: 12pt;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 20px;
}
.title {
    font-size: 15pt;
    margin-bottom: 20px;
    font-weight: 600;
}

.rowItem {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    overflow-wrap: anywhere;
    flex-direction: row;
    justify-content: start;
}

.rowItem span {
    margin-right: 10px;
}

.infoItemName {
    display: block;
    min-width: 150px;
    max-width: 150px;
    color: var(--text-primary);
    font-size: 17px;
    margin: 0 0 27px 0;
}

.infoItemValue {
    border: none;
    border-bottom: 1px solid #000;

    margin-left: 10px;
    width: 100%;
    background-color: transparent;
    font-size: 16px;
    color: var(--text-primary);

    padding: 0 0 5px 0;
    height: 21px;
}

.widgetSwitchVariants {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
}
.footer {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-top: 50px;
}

.dataError {
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
}
.dataErrorBlock{
    position: absolute;
    display: flex;
    top: 35px;
    right: 0px;
}
