.workSpace {
    width:100%;
    display:grid;
    grid-column-gap: 20px;
    grid-template-columns: auto 350px;
}
.samples {
    margin-left: 20px;
    margin-top: 20px;
    width: auto;
    height: max-content;
    overflow: hidden;
}
.closeButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #e4eff2;
    margin: 0 10px 0 auto;
    cursor: pointer;
    padding: 0;
    padding: 5px;
}

.sampleDeatilBox {
    position: absolute;
    top: 320px;
    right: 50px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    z-index: 7000;
    border: 2px solid grey;
    border-radius: 10px;
    padding: 20px 20px;
    background: var(--bg-secondary);
}

.sampleDeatilBox .row {
    display: flex;
    flex-direction: column;

}

.sampleTypescontainer {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    margin: 10px 0;
}
.sampleTypescontainer div{
    padding: 5px;
    cursor: pointer;
    border: 2px solid #d9e7eb;
}

.sampleTypescontainer div:hover{
    background-color: #d9e7eb;
    border-radius: 10px;
    border: 2px solid gray;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
}

.containerWorkSpace {
    width: 100%;
}

