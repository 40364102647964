.lable{
    width: 30%;
    text-align: right;
    padding-right: 20px;
}
.item{
    width: 60%;
}

.modal{
    width: 700px;
}

.checkboxContainer{
    display: flex;
}

.checkboxSelected{
    background-color: rgba(128, 128, 128, 0.1);
}
.checkbox{
    width: 18px;
    height: 18px;
    border: 2px solid grey;
    cursor: pointer;
    margin-right: 20px;
}

.checkboxText{

}

.dataError {
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
}

.dataErrorBlock{
    position: absolute;
    display: flex;
    top: 20px;
    left: 230px;
}