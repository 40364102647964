.modalBG {
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(19,57,43,0.54);

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    font-size: 17px;

    width: 400px;

    background: var(--bg-secondary);
    border-radius: 5px;

    display: grid;
    align-content: space-between;
    grid-template-rows: 60px auto 50px;
}

.model_copy {

    font-size: 17px;

    padding-bottom: 15px;
    min-width: 550px;

    background: var(--bg-secondary);
    border-radius: 5px;

    display: grid;
    align-content: space-between;
    grid-template-rows: 60px auto 50px;
}

.header {
    display: flex;
    justify-content: end;
    align-items: center;
}

.closeButton {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    display: grid;
    row-gap: 20px;
    min-height: 160px;
}

.bodyImage {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.bodyImage_self {
    height: 100%;
    width: 100%;
}
.bodyInfo {
    height: 100%;
    align-self: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.bodyInfoJE {
    justify-content: space-evenly;
}
.bodyTitle {

}
.bodyText {
    font-weight: normal;
}
.bodyButtons {
    display: flex;
    gap: 15px;
}


.button {
    border-radius: 50px;
    box-sizing: border-box;
    width: 130px;
    height: 36px;
    border: none;
    color: white;
    background: none;
    cursor: pointer;
    font-size: 15px;
    font-family: Ubuntu-R;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}


.info {
    background: #2ac49e;
}

.success {
    background: var(--color-brand);
}

.danger {
    background: #c32b2a;
}

.warning {
    background: #fe6e02;
}

.close {
    background: none;
    color: #9eabaf;
    border: solid 1px #9eabaf;
}

.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.title {
    font-size: 25px;
}

.infoItem {
    display: flex;
    flex-direction: row;
}

.infoItem_column {
    display: flex;
    flex-direction: column;
}

.infoItemName {
    display: block;
    min-width: 126px;
    color: var(--text-primary);
    font-size: 17px;
    margin: 0;
}

.mb5 {
    margin-bottom: 5px;
}



.infoItemValue {
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    width: 100%;
    font-size: 16px;
    color: var(--text-primary);
    background-color: transparent;
    padding: 0 0 5px 0;
    height: 21px;
}
.bgWhite {
    background-color: white !important;
    padding: 20px 20px;
    border-radius: 10px;
}
