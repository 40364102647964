.nameContainer{
    display: flex;
    width: 100%;
}

.modalContainer{
    margin-left: 30px;
    position: relative;
}

.container{
    display: flex;
}

.name{
    padding: 5px;
    cursor: pointer;
}

.arrow{
    align-self: center;
}

.arrowRight{
    rotate: -90deg;
    transition: rotate 0.3s ease;
    cursor: pointer;
}

.arrowDown{
    rotate: 0deg;
    transition: rotate 0.3s ease;
}

.horizotalLine{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.lineH{
    width: 100%;
    display:block;
    border-top: 2px solid black;
}

.activeSample{
    background-color: rgb(176,225,223);
}