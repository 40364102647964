.row {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
}
.rowWithTextarea {
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.row:first-child {
    margin-bottom: 20px;
}