.Login{
    width: 450px;
    height: 403px;
    background: var(--bg-secondary);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.LoginErrorWight{
    height: 462px;
}
.LoginBlock{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(19,57,43,0.54);
    justify-content: center;
    align-items: center;
}
.LoginInputBlock{
    display: flex;
    justify-content: space-between;
    font-family: Ubuntu-R;
    margin: 9px 0 -10px 0;
    padding: 10px 0;
    font-size: 16px;
    position: relative
}
.LoginInputBlockForget{
    margin: 18px 13px 16px 0;
    padding: 0 0;
}
.LoginButton{
    font-family: Ubuntu-R;
    background: var(--color-brand);
    color: white;
    display: flex;
    align-items: center;
    width: 130px;
    min-width: 130px;
    justify-content: space-evenly;
    border-radius: 100px;
    height: 36px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    margin-top: 10px;
}
.LoginButtonNewPass{
    margin-top: 24px;
}
.LoginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LoginText{
    font-family: Ubuntu-M;
    margin: 0 10px;
    color: var(--text-primary);
    letter-spacing: 0.04em;
}
.LoginInput{
    border: none;
    border-bottom: 1px solid;
    font-family: Ubuntu-R;
    background: var(--bg-secondary);
    width: 200px;
    padding: 0px 0 8px 0;
    font-size: 16px;
    color: var(--text-primary);
}
.LoginInputError{
    border-bottom: 1px solid #c12d2d;
}
.LoginInputBlock2{
    display: flex;
    align-items: flex-end;
}
.LoginButtonPass{
    margin: 0px 0 12px 3px;
    cursor: pointer;
}
.LoginButtonForget{
    font-family: Ubuntu-R;
    color: var(--text-primary-brand);
    margin: 6px 36px 19px 200px;
    text-align: right;
    cursor: pointer;
    font-size: 14px;
}
.LoginLogo{
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0 0 10px 0;
}
.ForgetBlock{
    width: 450px;
    height: 440px;
    background: var(--bg-secondary);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
}
.ForgetInputBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 18px 0 0px 0;
    line-height: 1.4;
    font-size: 15px;
}
.ForgetText{
    font-family: Ubuntu-R;
    margin: 0px 45px 3px 45px;
    text-align: center;
    color: var(--text-primary);
}
.ForgetText2{
    font-family: Ubuntu-R;
    margin: 17px 45px 10px 45px;
    text-align: center;
    color: var(--text-primary);
}
.LoginLogoTop{
    margin-bottom: 28px;
    margin-top: 48px;
}
.LoginError{
    width: 290px;
    margin: -21px 0 15px 25px;
}
.LoginError2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.LoginError2 img {
    margin-right: 10px;
}
.LoginErrorText{
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
}
.LoginErrorText2{
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
    width: 168px;
}
.arrowImg {
    position: absolute;
    top: 22%;
    left: 98%;
    padding: 0.5rem;
}

.LoginDropdown {
    border: none;
    border-bottom: 1px solid;
    font-family: Ubuntu-R;
    background: #f1f7fc;
    width: 220px;
    overflow-y: auto;
    padding: 0px 0 8px 0;
    font-size: 16px;
    position: absolute;
    z-index: 10;
    left: 28%;
    top: 82%;
    border: 1px solid rgba(85, 85, 85, 0.2);
    border-radius: 10px;
    padding: 10px 0 0 0;
    height: 243px;
}

.LoginDropdown::-webkit-scrollbar {
    position: absolute;
    left: 14%;
    width: 20px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
}

.LoginDropdown::-webkit-scrollbar-thumb {
    background-color: #aebbbe;
    border-radius: 10px;
    border: 5px solid #f1f7fc;
}

.dropdownGroupName{
    padding-left: 17px;
    font-weight: 700;
    padding-bottom: 2px;
    margin: 0;
    font-family: Ubuntu-M;
    letter-spacing: 0.05em;
    color: var(--text-secondary);
}
.dropdownGroup{
    padding-top: 20px;
}
.dropdownGroup:first-of-type{
    padding-top: 0;
}

.dropdownItemName {
    font-weight: 500;
    padding: 7px 0 7px 30px;
    margin: 0;
    cursor: pointer;
    color: var(--text-primary);
}

.dropdownItemName:hover {
    background: #a2e3df;
}
.close{
    background: var(--bg-primary);
    padding: 10px;
    border-radius: 20px;
    height: 15px;
    right: 10px;
    position: absolute;
    top: 10px;
    cursor: pointer;
}
.errorBlock{
    position: absolute;
    display: flex;
    top: 26px;
    left: 63px;
}
.errorBlock2{
    display: flex;
}
