.container * {
    box-sizing: border-box;
}

.container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end;
}

.swatch {
    padding:  0 5px 5px 5px;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.color {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.arrowIconContainer {
    display: flex;
    align-items: center;
}

.arrowIcon{
    transform: translate(0, -50%);
    transition: 0.1s;
    cursor: pointer;
    margin-left: 5px;
}

.opened {
    transform: rotateZ(180deg) translateY(2px);
}