


.mainBlock {
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 155px;
    z-index: 18;
    background: #f1f7fc;
    overflow-y: auto;
}

.mainBlock::-webkit-scrollbar {
    width: 20px !important;
    /*padding: 5px;*/
}
.mainBlock::-webkit-scrollbar-track {
    width: 10px !important;
    /*padding: 5px;*/
}

.mainBlock::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.resizableContent {
    position: relative;
}
.textarea {
    position: absolute;
    resize: none;
    box-sizing: border-box;
    width: 100%;
    border: none;
    line-height: 35px;
    color: var(--text-secondary);
    background: none;
    font-family: Ubuntu-R;
    padding: 10px 20px;
    font-size: 16px;
    z-index: 22;
}

.selectedLine {
    pointer-events: none;
    margin-top: 10px;
    position: absolute;
    width: 100%;
    height: 35px;
    background: #a2e3df;
}