td {
    position: relative;
}

.dataError {
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
}
.dataErrorBlock{
    position: absolute;
    display: flex;
    margin: -3px 0px 0 0px;
}

.dataErrorBlockCont{

}

.dataErrorBlockDinamyc{
    margin: -22px 0px 0 0px;
    right: 118px;
}

.rowUserFiledContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rowUserFiledName{
    width: 30%;
}

.rowUserFiledComponent{
    width: 60%;

}

.separatingRow{
    margin: 20px auto;
    border: 2px dashed gray;
    text-align: center;
    padding: 10px 0;
}

.imgType{
    object-fit: contain;
    width: 74%;
    height: 74%;
}

.separatingRowLine{

}

.workRightAddMenuUser{
    height: calc(100% - 15px);
    width: 100%;
    background: rgba(255,255,255,1);
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 1;
}
.workRightAddMenuUserBlock1{
    margin: 40px 20px 0 14px;
    border-collapse: collapse;
}
.workRightAddMenuUserBlock1Img{
    height: 180px;
    width: 236px;
}
.workRightAddMenuUserBlock1ImgBlock{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center
}
.workRightAddMenuUserBlock1InputBlock{
    display: flex;
    margin: 0px 0 16px 0;
}
.workRightAddMenuUserBlock1InputBlockText{
    font-family: Ubuntu-M;
    padding-right: 8px;
    padding-bottom: 14px;
    min-width: fit-content;
    white-space: nowrap;
    display: flex;
    margin: -3px 0 0 0;
    color: var(--text-primary);
    width: 137px;
}
.workRightAddMenuUserBlock1InputBlockInput{
    width: 100%;
    font-family: Ubuntu-R;
    border: none;
    border-bottom: 1px solid var(--border-gray);
    font-size: 15px;
    margin: -23px 0px 0px -8px;
    display: block;
    padding: 0px 0 7px 0;
}

.workRightAddMenuUserBlock1InputBlockInput::-webkit-calendar-picker-indicator{
    padding-left: calc(100% - 100px);
    cursor: pointer;
    color: transparent;
    opacity: 1;
    background: url(./../../../../svg/arrow-01.svg) no-repeat center right;
}

.workRightAddMenuUserBlock1InputBlockInput:nth-child(3){
    max-width: 110px;
}

.workRightAddMenuUserBlock1Input{
    width: calc(100% - 200px);
}
.workRightAddMenuUserBlock2{
    margin: 0 20px 0 15px;
    display: flex;
}
.workRightAddMenuUserBlock1InputBlockText_2{
    width: 53px;
    margin: -4px 0 12px 0;
}
.workRightAddMenuUserBlock1InputBlockInput_2{
    color: green;
    margin: -17px 0px 3px -9px;
    border-bottom: 1px solid var(--border-gray);
    letter-spacing: 0.04em;
}
.workRightAddMenuUserBlock3{
    font-family: Ubuntu-M;
    letter-spacing: 0.05em;
    margin: -2px 18px 7px 18px;
}
.workRightAddMenuUserBlock3TextArea{
    font-family: Ubuntu-R;
    font-size: 14px;
    border: none;
    width: 100%;
    height: 40px;
    margin: 12px 0 0 -2px;
    color: var(--text-secondary);
    resize: none;
    display: inline-table;
}
.workRightAddMenuUserBlock4{
    margin: 0 15px 0 17px;
}
.workRightAddMenuUserBlock4Text{
    font-family: Ubuntu-M;
    font-size: 20px;
    margin: -2px 0 0 20px;
    letter-spacing: 0.03em;
    color: #bbbbbb;
}
.workRightAddMenuUserBlock4Block{
    background: #f1f7fc;
    border-radius: 5px;
    font-family: Ubuntu-R;
    padding-top: 12px;
    padding-bottom: 30px;
}
.workRightAddMenuUserBlock4Block2{
    display: flex;
    margin: 5px 0px 9px 40px;
}
.workRightAddMenuUserBlock4Block2Text{
    font-family: Ubuntu-R;
    width: 270px;
    padding: 1px 10px 6px 0;
}
.workRightAddMenuUserBlock4BlockText{
    margin: 13px 0px 10px 20px;
    font-size: 17px;
    color: #bbbbbb;
}
.workRightAddMenuUserBlock4BlockInput{

}
.workRightAddMenuUserBlock5{
    display: flex;
    margin: 40px 0px 18px 38px;
}
.workRightUsersAddMenuBlockInput{
    display: flex;
    margin: 37px 60px 33px 30px;
}
.workRightAddMenu_blockButton_Users{
    display: flex;
    margin: 0 0 0 11px;
}
.workRightAddMenuUserAvatar{
    width: 140px;
    height: 140px;
    border-radius: 100%;
    z-index: 3;
    background: white;
    object-fit: cover;
    margin: 0 17px 38px 0;
}
.workRightAddMenuUserDeleteAvatarBlock{
    background: rgb(180,194,199);
    position: absolute;
    height: 40px;
    width: 28px;
    padding: 3px 0 0 0;
    transform: rotate(45deg);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    left: 120px;
    top: 40px;
    z-index: 1;
    transition: 0.2s all ease;
    cursor: pointer;
}
.workRightAddMenuUserDeleteAvatar{
    -webkit-mask-image: url('../../../../svg/delete-01.svg');
    width: 15px;
    height: 16px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    margin: 0 0 2px 1px;
    cursor: pointer;
    transform: rotate(-45deg);
}
.workRightAddMenuUserEditAvatarBlock{
    background: rgb(180,194,199);
    position: absolute;
    height: 40px;
    width: 28px;
    padding: 3px 0 3px 0px;
    transform: rotate(45deg);
    border-radius: 100px;
    justify-content: center;   
    left: 50%;
    top: 25%;
    z-index: 1;
    align-items: flex-end;
    display: flex;
    transition: 0.2s all ease;
    cursor: pointer;
    font-family: 'Ubuntu-R';
    font-size: 11pt;
    color: red;
}
.workRightAddMenuUserEditAvatar{
    -webkit-mask-image: url('../../../../svg/edit-01.svg');
    width: 15px;
    height: 16px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    margin: 0 0 2px 1px;
    cursor: pointer;
    transform: rotate(-45deg);
}
.workRightAddMenuUserBlock1ImgBlock:hover > .workRightAddMenuUserEditAvatarBlock{
    top: 96px;
    left: 41px;
}
.workRightAddMenuUserBlock1ImgBlock:hover > .workRightAddMenuUserDeleteAvatarBlock{
    top: -3px;
    left: 142px;
}

.profileInfoItem {
    display: flex;
    align-items: center;
}

.profile  {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 43px;
}

.profileVisual {
    height: 100%;
    width: 18%;
}

.profileInfo {
    width: 82%;
}

.status {
    display: flex;
    align-items: center;
    margin: 42px 0 0 14px;

}

.profileInfoItem {
    margin-bottom: 16px;
}
.ReportsItem{
    margin: 0 0 10px 40px;
}
.ReportsItemBlock{
    display: flex;
    cursor: pointer;
}
.ReportsItemBlockText{
    font-family: Ubuntu-M;
}
.ReportsItemBlockUp{
    -webkit-mask-image: url('../../../../svg/arrow-01.svg');
    width: 12px;
    height: 7px;
    background: #000000;
    margin: 7px 10px 0 0;
    transform: rotate(-180deg);
    transition: 0.2s all ease;
}
.ReportsItemBlockItem{
    margin: 7px 55px 10px 53px;
    display: flex;
    justify-content: space-between;
}
.ReportsItemBlockItemText{
    margin: 5px 0 0 0;
}
.errorInput{
    border-bottom: 1px solid #c12d2d;
}
.emptyBlock{
    min-width: 227px;
    height: 45px;
}
