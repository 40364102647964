.rs-progress-info {
    position: absolute;
    left: 50%;
    top: calc(50% + 1px);
    transform: translate(-50%, -50%);
}

.rs-progress-line-bg, .rs-progress-line-outer {
    align-items: start;
}

.rs-progress-line-inner {
    background-color: #a2e3df;
}

.rs-progress-line {
    position: relative;
}

.rs-progress-info-status {
    margin-top: auto;
    margin-bottom: auto;
}

.rs-progress-line {
    display: inline-flex;
    box-sizing: border-box;
    padding: 0;
    flex-direction: row;
    max-width: 272px;
}

.rs-progress-line + i {
    display: inline-block;
}

.d-none {
    display: none;
    visibility: hidden;
}

.rs-progress-info-status {
    font-size: 14px;
    color: var(--text-primary);
}