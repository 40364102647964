.WorkLeftTableBlock{
    overflow: auto;
    height: calc(100% - 70px);
}
.WorkLeftTable{
    //width: calc(100% - 15px);
    //margin: 0 15px;
    border-collapse: collapse;
}
.workLeftTableRow:hover > td > .workLeftTableItem_Button{
    background-color: var(--color-brand);
}
.workLeftTableRow:hover {
    color: var(--color-brand);
}
.workLeftTableRow:hover > td > .workLeftTableItemText2{
    border-bottom: 1px solid #bbbbbb;
}
.workLeftTableRowActive{
    background: rgb(176,225,223);
}
.workLeftTableItem{
    cursor: pointer;
    width: 100%;
    padding: 5px 0 ;
}
.workLeftTableItemLast{
    color: var(--text-primary);
    display: flex;
    float: right;
    margin-right: 10px;
}
.workLeftTableItem_Button{
    background-color: #DCE6E9;
    height: 17px;
    margin: 6px 0 7px 0;
    width: 25px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    float: left;
    cursor: pointer;
}
.workLeftTableItem_ButtonActive{
    background-color: rgb(176,225,223);
}
.workLeftTableItemText{
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #DCE6E9;
    padding: 0 0 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
    width: max-content;
}
.workLeftTableItemTextActive{
    border-bottom: 1px solid rgb(176,225,223);
}
.workLeftTableItemText2{
    margin: -1px 0 0 0;
    z-index: 2;
    border-bottom: 1px solid #DCE6E9;
}
.workLeftTableItemText2Active{
    border-bottom: 1px solid rgb(176,225,223);
}