.periodTimeContainer {
    position: relative;
    width: 100%;
    font-family: 'Ubuntu', sans-serif;

}

.arrowIcon {
    position: absolute;
    right: 5px;
    top: calc(30% + 0px);
    transform: translate(0, -50%);
    transition: 0.1s;
}

.opened {
    transform: rotateZ(180deg) translateY(2px) !important;
}
