.row {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
}
.rowWithTextarea {
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.row:first-child {
    margin-bottom: 20px;
}
.textarea {
    box-sizing: border-box;
    padding: 10px;

    background: #f1f7fc;
    border-radius: 10px;
}
.textMuted {
    color: var(--border-gray);
    font-weight: normal;
}
.sizeInput {
    width: 140px;
}