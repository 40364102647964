.leftGrid{
    width: 100%;
    border-collapse: collapse;
    height: 60%;
    overflow-y: scroll;
    display:block;
}

.leftGrid th{
    text-align: left;
    padding: 10px;
}

.leftGrid td{
    text-align: left;
    padding: 10px;
}

.rowLeftGrid{
    border-bottom: 1px solid black;
}

.colLeftName{
    width: 40%;
}

.colLeftType{
    width: 30%;
}

.colLeftReq{
    width: 30%;
}

.rightGrid{
    width: 100%;
    border-collapse: collapse;
    height: 90%;
    overflow-y: scroll;
    display:block;
}

.rightGrid th{
    text-align: left;
    padding: 10px;
}

.rightGrid td{
    text-align: left;
    padding: 10px;
}

.colRightName{
    width: 60%;
}

.colRightType{
    width: 40%;
}


.tables{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.leftGridContainer{
    width: 65%;
    max-height: 65vh;
}

.rightGridContainer{
    width: 35%;
    max-height: 65vh;
}

.arrowIcon {
    position: absolute;
    right: -10px;
    top: calc(50% - 4px);
    transform: translate(0, -50%);
    transition: 0.1s;
}

.opened {
    transform: rotateZ(180deg) translateY(2px);
}

.btnStort{
    position: relative;
}

.tableLeftContainer{
    width: 59%;
}

.tableRightContainer{
    width: 40%;
    max-height: 65vh;
}

.tableLeftRow{
    display: flex;
    width: 100%;
    border-bottom: 1px solid black;
    cursor: grab !important;
}

.tableRightRow{
    display: flex;
    position: relative;
    width: 100%;
}

.moreInfoRow{
    padding: 1% 12%;
    max-width: 80%;
    word-break: break-all;
}

.moreInfoRowTextNoraml{
    font-size: 0.9rem;
    font-weight: normal;
}

.tableRightRowContainer{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
}

.tableRightRow div{
    padding: 10px;
}

.tableLeftRow{
    cursor: move;
}

.tableLeftRowHead{
    border: none;
    display: flex;
    padding-right: 10px;
    background-color: #F1F7FC;
    border-radius: 10px;
}

.tableLeftRowHead div{
    padding: 10px;
}

.tableLeftRow div{
    padding: 10px;
}

.leftRowName{
    width: 35%;
}

.rightRowName{
    width: 45%;
    display: flex;
}

.workRightTableSortBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19px;
    align-items: center;
    width: 15px;
    margin-left: 10px;
    padding: 0 !important;
}

.workRightTableSortUpNot{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    width: 15px;
    height: 15px;
    background-color: slategrey;
    -webkit-mask-repeat: no-repeat;
    transform: rotate(180deg) scaleX(-1);
    margin: 0 0 3px 0;
    cursor: pointer;
    padding: 0 !important;
}

.workRightTableSortDownNot{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    width: 15px;
    height: 15px;
    background-color: slategrey;
    -webkit-mask-repeat: no-repeat;
    cursor: pointer;
    padding: 0 !important;
}

.workRightTableSortUp{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    background-color: var(--color-brand);
    width: 15px;
    height: 15px;
    -webkit-mask-repeat: no-repeat;
    transform: rotate(180deg) scaleX(-1);
    margin: 0 0 3px 0;
    cursor: pointer;
    padding: 0 !important;
}
.workRightTableSortDown{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    background-color: var(--color-brand);
    width: 15px;
    height: 15px;
    -webkit-mask-repeat: no-repeat;
    cursor: pointer;
    padding: 0 !important;
}

.rightRowType{
    width: 40%;
    display: flex;

}

.leftRowReq{
    width: 30%;
}

.leftRowType{
    width: 30%;
}

.tableLeftBody{
    overflow-y: scroll;
    max-height: 45vh;
}

.tableRightBody{
    overflow-y: scroll;
    height: 45vh;
}

.isDisable{
    cursor: not-allowed !important;
}

.imgAdd{
    background-color: #01B454;    
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;
}

.imgAdd:hover .tooltip{
    display: block;
}

.tooltip:hover{
    display: none !important;
}

.iconContainer{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 0 !important;
}

.leftHeaderButton{
    width: 59%;
    display: block;
}

.rightHeaderButton{
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.containerHeaderButton{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.containerHeaderTotal{
    display: flex;
    margin-bottom: 15px;
}

.btnAddField{
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.btnAddContainer{
   display: inline-block;
   position: relative;
   cursor: pointer;
   display: flex;
   width: 40%;
}

.iconAddContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    
}

.textAddContainer{
    margin-right: 5px;
    padding: 5px;
    user-select: none;
    font-size: 1em;
}

.flippedArrowIcon {
    transform: rotate(180deg);
}

.arrowAddIcon{
    transition: 0.1s;
}

.selectTextDD{ 
    position: absolute;
    top: 25px;
    width: 100%;
    display: block;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #f2f8fd;
    z-index: 1;
    padding: 10px 0 10px 10px;
    box-shadow: 0 5px 10px 5px rgba(89, 102, 109, 0.17);
    border-radius: 5px;
    color: #59666d;
    font-weight: normal;
    font-family: 'Ubuntu', sans-serif;
}

.selectTextDDopacity{
    display: none;
}

.selectTextItemDD{
    padding: 10px;
}

.selectTextItemDD:hover{
    background-color: #a2e3df;
}

.WorkLeftHeader_search{
    background: #f4f7fc;
    border-radius: 100px;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 400px;
}
.WorkLeftHeader_search_svg{
    height: 15px;
    padding: 0 6px 0 7px;
}
.WorkLeftHeader_search_input{
    background: #f4f7fc;
    width: 100%;
    border-radius: 0 100px 100px 0;
    border: none;
    font-size: 15px;
    font-family: Ubuntu-R;
}

.chackboxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 0 !important;
    width: 10%;
}

.leftTabContainer {
    height: 88%;
}
.checkbox{
    width: 13px;
    height: 13px;
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid gray;
    display: flex;
    padding: 0 !important;
    align-self: center;
    align-items: center;
    cursor: pointer;
}

.tooltipInfo{
    top: 15px !important;
}

.checkbox:hover .tooltip{
    display: block;
}

.bgcCkeck{
    background-color: #f1f7fc;
}

.tableLeftRowClear{
    text-align: center;
    display: block;
    padding: 10px 0;
    cursor: default !important;
    color: #59666d;
}

.center{
    display: flex;
    flex-direction: column;
    align-self: center;
}
.tooltip{
    position: absolute;
    top: 35px;
    left: 0;
    right: auto;
    width: auto;
    display: none;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    z-index: 4000;
    color: var(--border-gray);
}
.opacity{
    opacity: 0;
}

body.dragging {
    cursor: pointer !important;
}

.collapsContainer{
    width: 5%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.collaps{
    border: 1px solid gray;
    width: 10px;
    height: 10px;
    padding: 0 !important;
    margin: 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.collaps:hover .tooltip{
    display: block;
}

.minusInCollaps{
    display: block;
    border-bottom: 2px solid gray;
    width: 100%;
    padding: 0px !important;
}


