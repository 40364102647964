.line{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 20px;
}.Path{
    font-family: Ubuntu-R;
    display: flex;
    align-items: baseline;
    gap: 5px;
}
.Path_text1{
    color: grey;
}
.Path_text2{
    color: grey;
    transform: rotate(-90deg);
}
.Path_text3{
    color: #50B160;
}

.arrowBack {
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #50B160;
    border-radius: 100px;
    transform: rotate(90deg);
    transition: 0.3s all ease;
    cursor: pointer;
}