.container * {
    box-sizing: border-box;
}

.arrowIcon {
    position: absolute;
    right: -10px;
    top: calc(50% - 4px);
    transform: translate(0, -50%);
    transition: 0.1s;
}

.opened {
    transform: rotateZ(180deg) translateY(2px);
}