.input {
    background: #DCE6E9;
    border: none;
    border-bottom: 1px solid var(--border-gray);
    color: var(--text-primary);
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
    resize: none;
    padding: 0 0 6px 10px;
}