
.dropdownSelf {
    position: relative;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: var(--text-secondary);
}

.label {
    display: flex;
    align-items: start;
}

.labelIcon {
    margin-right: 10px;
}

.containerSelectedItem {
    display: grid;
    grid-template-columns: auto 25px;
}

.containerSelectedItemFull {
    display: grid;
    grid-template-columns: auto;
}

.infoIcon {
    justify-self: end;
}

.selectedItem {
    cursor: auto;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--text-secondary);
}
.dropdownError {
    position: absolute;
    bottom: -15px;
    display: flex;
    padding-left: 10px;
    gap: 10px;
    align-items: center;
    color: #c12d2d;
    font-size: 13px;
    font-weight: 400;
}


.selectedItemDisabled {
    cursor: not-allowed;
}
.selectedItemError {
    border-bottom: 1px solid #c12d2d;
    color: #c12d2d;
}

.arrowIcon {
    transition: 0.1s;
}
.flippedArrowIcon {
    transform: rotate(180deg);
}

.droppedPanel {
    z-index: 1000;
    margin-top: 0.5em;
    border-radius: 5px;
    box-shadow: 0 5px 10px 5px rgba(89, 102, 109, 0.17);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    max-height: 200px;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    background: var(--bg-textarea);
    padding: 10px;
}
.droppedPanelWithInfo {
    width: Calc(100% - 25px);
}

/* manual item */

.item {
    display: flex;
    padding: 10px 0;
    font-size: 1em;
    cursor: pointer;
    min-height: 20px;
    height: auto;
    width: 100%;
    justify-content: space-between;
}

.item span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100%);
}

.item:hover {
    padding: 10px 0;
    font-size: 1em;
    background: #cef5f1;
}

.itemSelected {
    background: #a2e3df;
}

.itemSelected:hover {
    background: #8ad2ce;
}

.itemIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.itemIconsContainer div:first-child {
    min-height: 32px;
    border-bottom: unset;
}

.itemIconsContainer > div > div {
    justify-content: space-between;
    align-items: start;
    max-width: 70px;
    cursor: pointer;
    user-select: none;
}

.itemIconsContainer > div > div * {
    margin-top: auto;
    margin-bottom: auto;
}

.itemTreeContainer div:last-child {
    min-height: 20px;
}

.treeDropPanel {
    top: 30px;
}

.treeDropPanel div:last-child {
    min-height: unset !important;
}

.iconsDropPanel div, .iconsDropPanel img {
    margin: 0;
    height: auto;
    min-height: 22px !important;
}

.iconsDropPanel > img, .iconsDropPanel div {
    margin-right: 17px;
}

.iconsDropPanel *:nth-child(6n) {
    margin-right: 0;
    margin-bottom: 17px;
}

.iconsDropPanel *:last-child {
    margin-bottom: 0;
    margin-right: 0;
}

.iconsDropPanelOpened {
    z-index: 110;
    min-width: 237px;
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
}

.iconsDropPanel {
    right: calc(100% - 70px);
    top: 40px;
    max-width: 175px;
    flex-direction: row;
    flex-wrap: wrap;
    user-select: none;
}

.checkboxDropPanel *:not(:last-child) {
    margin-bottom: 5px;
}

.checkboxDropPanelOpened {
    display: flex !important;
}

.checkboxDropPanel {
    max-height: 115px;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
}

/*Media*/

@media only screen and (max-width: 1024px) {
    .label span {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
