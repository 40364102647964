.autoCompleteContainer {
    margin: 10px;
    font-family: Ubuntu-R;
    font-size: 17px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.title {
    margin-left: 10px;
}

.addContainer {
    margin-bottom: 30px;
    background-color: var(--bg-secondary);
    padding: 20px;
    border-radius: 10px;
}

.itemRow {
    display: flex;
    flex-direction: row;
    width: 550px;
    margin-bottom: 20px;
    align-items: center;
}

.packRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 550px;
    margin-bottom: 20px;
}

.infoItemValue {
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    max-width: 100px;
    font-size: 16px;
    color: var(--text-primary);
    background-color: transparent;
    margin-left: 10px;
    height: 20px;
}
.buttonClear {
    position: relative;
    height: 21px;
    width: 30px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b0e1df;
    margin-left: 10px;
}
.buttonClear div {
    width: 13px;
    height: 13px;
}
