.modalContainer{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    background: rgba(19,57,43,0.54);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal{
    font-size: 17px;
    width: 615px;
    padding: 15px;
    background: var(--bg-secondary);
    border-radius: 5px;
    display: grid;
    align-content: space-between;
    grid-template-rows: 60px auto 50px;
}

.footer{
    margin: 10px auto;
    display: flex;
}

.rowType{
    margin: 20px 0;
}

.dataErrorBlock{

    display: flex;
    margin: 20px 0 0 0;
}

