.row {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 15px;
    width: 260px;
    margin-bottom: 25px;
}
.rowPercent {
    align-items: center;
}
.col {
    width: 50%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.typeSelect {
    width: 100%;
}


.row:last-child {
    margin-bottom: 0;
}
.textarea {
    box-sizing: border-box;
    padding: 10px;
    background: #f1f7fc;
    border-radius: 10px;
}

.textMuted {
    color: var(--border-gray);
    font-weight: normal;
}

.colorField {
    display: flex;
}

.infoItemGraphicValue {
    position: relative;
    width: 32px;
    height: 32px;
    margin: -5px 0 0 16px;
}

.infoItemGraphicValue2 {
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--color-brand);
    margin: -7px 0 0px 17px;
}


.sizeInput {
    width: 65px;
}

.baseSelects {
    width: 100%;
}


.baseSelects > *:first-child {
    margin-bottom: 25px;
}

.colorDropdown{
    position: absolute;
    background: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
    z-index: 5;
}
.colorRound {
    display: flex;
    gap: 10px ;
    align-items: center;
}