.selectTemplate{
    width: 80%;
    margin: 0 auto;
}

.swichTabs{
    background-color: #a2e3df;
    display: flex;
    border-radius: 15px;
}

.selectedTab{
    background-color: var(--color-brand);
    color: var(--bg-primary);
}

.disable{
    cursor: not-allowed !important;
}

.titleModal{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    user-select: none;
}

.printableContent{
    width: 100%;
    text-align: center;
    height: 10mm;
    display: none;
}

.page {
    page-break-after: always;
}

@media print {
    .printableContent{
        width: 100%;
        text-align: center;
        height: 10mm;
        display: block;
    }
}

.itemBlock{
    margin: 30px 0;
    position: relative;
    user-select: none !important;
}

.addNewTemplateBtn{
    font-family: Ubuntu-R;
    font-size: 11pt;
    border-radius: 100px;
    padding: 10px 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
    user-select: none;
}

.addTemplateInput{
    background: #DCE6E9;
    border: none;
    border-bottom: 1px solid var(--border-gray);
    color: var(--text-primary);
    width: 80%;
    font-family: Ubuntu-R;
    font-size: 15px;
    resize: none;
    padding: 0 0 6px 10px;
    display: block;
}

.addTemplateContainer{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dataErrorBlock{
    position: absolute;
    display: flex;
    top: 22px;
}

.dataError {
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
}

.bodyContainer{
    width: 100%;
}

.selectTemplateContainer{
    margin-bottom: 40px;
    position: relative;
}

.modal{
    font-size: 17px;
    width: 615px;
    padding: 15px;
    background: var(--bg-secondary);
    border-radius: 5px;
    display: grid;
    align-content: space-between;
}

.selectSizeContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.lableNameSize{
    width: 50%;
}