.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 314px;
    margin-bottom: 20px;
}

.container * {
    box-sizing: border-box;
}

.container i {
    cursor: pointer;
}

.rangeFromProgress {
    position: absolute;
    top: 23px;
    left: 1px;
    width: 272px;
    height: 19px;
    z-index: 8;
    background-color: #DCE6E9;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.rangeFromProgress > div {
    margin-top: auto;
}

.containerOpened i {
    background-color: var(--text-primary) !important;
}