.container * {
    box-sizing: border-box;
}

.container {
    margin-top: -3px;
    width: 100%;
}

.container > div > div:first-child > div {
    margin-left: auto;
}

.container > div > div:nth-child(2) {
    right: -2px !important;
}