.modalContainer{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    background: rgba(19,57,43,0.54);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal{
    font-size: 17px;
    width: 615px;
    padding: 15px;
    background: var(--bg-secondary);
    border-radius: 5px;
    align-content: space-between;
}

.body{
    width: 80%;
    margin: 0 auto;
}

.footer{
    margin: 30px auto 0;
    width: 80%;
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: end;
    align-items: center;
}

.titleModal{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.closeButton {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.item{
    text-align: center;
    margin: 20px 0;
    border: 1px solid var(--color-brand);
    background-color: var(--color-brand);
    border-radius: 15px;
    padding: 10px 0;
    cursor: pointer;
    color: var(--bg-primary);
}
