/* Group of radio buttons */
.radio_group {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.form_radio input[type=radio] {
    display: none;
}

.form_radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 0;
    line-height: 18px;
    user-select: none;
}

.form_radio label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 18px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background: url(../../png/radio-1.png) 0 0 no-repeat;
}

/* Checked */
.form_radio input[type=radio]:checked + label:before {
    background: url(../../png/radio-2.png) 0 0 no-repeat;
}

/* Hover */
.form_radio label:hover:before {
    filter: brightness(120%);
}

/* Disabled */
.form_radio input[type=radio]:disabled + label:before {
    filter: grayscale(100%);
}