.input_datetime {
    width: 100%;
    padding: 0 6px;
    background: none;
    border: none;
    border-bottom: 1px solid #000;
}

.input_datetime > input {
    background-color: transparent;
    border: unset;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: var(--text-primary);
    cursor: pointer;
    padding-bottom: 5px;
}

.input_datetime > input + div {
    right: 0;
    top: 33px;
}

.containerDatePicker table thead span {
    font-size: 28px;
}

.containerDatePicker > div {
    border-color: #738087 !important;
}

.containerDatePicker table thead tr:first-child th:last-child {
    font-size: 28px;
}

.containerDatePicker tfoot {
    height: 30px;
    width: 20px;
}

.arrowIcon {
    position: absolute;
    right: -10px;
    top: calc(35% - 1px);
    transform: translate(0, -50%);
    transition: 0.1s;
    cursor: pointer;
}

.containerDatePicker {
    position: relative;
}
