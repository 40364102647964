.Item{
    transition: 0.3s all ease;
    margin-left: 30px;
    position: relative;
}
.ItemName{
    cursor: pointer;
    display: flex;
    margin: 0px 10px 0px 0;
    white-space: nowrap;
}
.ItemNameSelect{
    background: rgb(176,225,223);
}
.Strelka{
    transition: 0.3s all ease;
    margin: 11px 0px 0 0;
}
.SVG{
//background-color: var(--text-primary);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    float: left;
    cursor: pointer;
    margin: 0 5px 0 0;
//filter: invert(16%) sepia(79%) saturate(320%) hue-rotate(106deg) brightness(95%) contrast(97%)
}
.ItemNameBlock:hover > .SVG{
//background-color: var(--color-brand);
    filter: invert(51%) sepia(36%) saturate(6359%) hue-rotate(122deg) brightness(97%) contrast(103%);
}
.Line{
    border-left: 1px solid #bbbbbb;
    border-bottom: 1px solid #bbbbbb;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -24px;
    top: -5px;
    transition: 0.3s all ease;
}
.Line2{
    transition: 0.3s all ease;
    border-left: 1px solid #bbbbbb;
    height: 100%;
    position: absolute;
    left: -24px;
}
.Block{
    display: flex;
}
.ItemNameBlock:hover > .Block > .Item_Button{
    background-color: var(--color-brand);
}
.Item_Button{
    background-color: #DCE6E9;
    height: 17px;
    width: 25px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    float: left;
    cursor: pointer;
}
.ItemNameBlock:hover > .InvBlock{
    border-bottom: 1px solid #bbbbbb;
}
.ItemNameBlock:hover >.ItemNameText{
    color: var(--color-brand);
}
.InvBlock{
    width: 100%;
}
.ItemNameBlock{
    display: flex;
    width: 100%;
    padding: 6px 0 6px 5px;
}
.ButtonActive{
    background: rgb(176,225,223);
}