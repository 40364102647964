.LeftMenu{
    background: #a2e3df;
    height: 100%;
    width: 220px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s all ease;
}
.LeftMenu_small{
    width: 70px;
}
.LeftMenu_block1{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.LeftMenu_logo{
    height: 36px;
    padding-top: 29px;
    padding-bottom: 60px;
}
.LeftMenu_Menu{

}

.reletiveBlock{
    background: #e4eff2;
    position: relative;
    display: flex;
    flex-direction: column;

}

.LeftMenu_Menu_Item {
    display: flex;
    align-items: center;
    padding: 14px 5px 14px 35px;
    width: 180px;
    font-size: 14px;
    font-family: Ubuntu-R;
    cursor: pointer;
    transition: 0.3s all ease;
    position: relative;
}
.LeftMenu_Menu_Item_Active{
    background: #e4eff2;
    position: relative;
    color: var(--text-primary-brand);
    stroke: var(--text-primary-brand);
    fill: var(--text-primary-brand);
}

.LeftMenu_Menu_Item_Active_Img{
    background-color: var(--text-primary-brand) !important;
}

.LeftMenu_Menu_Item:hover{
    background: #e4eff2;
    color: var(--text-primary-brand);
    stroke: var(--text-primary-brand);
    fill: var(--text-primary-brand);
}

.LeftMenu_Menu_Item_Relative{
    position: relative;
}
.LeftMenu_Menu_Item_small{
    width: 70px;
    padding: 15px 0;
    justify-content: center;
}
.LeftMenu_Menu_Item_text{
    padding-left: 10px;
    opacity: 0.8;
}
.LeftMenu_Menu_Item_img{
    width: 8px;
    height: 8px;
    background-color: #e4eff2;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: 0.3s all ease;
    -webkit-mask-size: contain;
    border-radius: 50%;
}
.LeftMenu_Menu_Item:hover > .LeftMenu_Menu_Item_img{
    background-color: var(--text-primary-brand);
}
.LeftMenu_Menu2{

}
.LeftMenu_footer{
    display: flex;
    font-size: 14px;
    justify-content: space-evenly;
    padding: 30px 0px
}
.LeftMenu_Menu_Item2 {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 30px;
    width: 190px;
    font-size: 16px;
    font-family: Ubuntu-R;
    cursor: pointer;
    transition: 0.3s all ease;
    color: var(--text-primary-brand);
}
.LeftMenu_Menu_Item2_small{
    width: 70px;
    padding: 15px 0;
    justify-content: center;
}
.LeftMenu_Menu_Item_text2{
    padding-left: 10px;
}
.LeftMenu_Menu_Item_img2{
    width: 18px;
    height: 18px;
    background-color: var(--text-primary-brand);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: 0.3s all ease;
    -webkit-mask-size: contain;
}
.LeftMenu_a{
    font-family: Ubuntu-R;
    color: var(--text-primary-brand);
}
.LeftMenu_version{
    font-family: Ubuntu-R;
}

.LeftMenu_version:hover {
    cursor: pointer;
}
