.checkboxContainer{
    display: flex;
}

.modal{
    width: 600px;
}

.checkboxSelected{

}
.checkbox{
    width: 18px;
    height: 18px;
    border: 2px solid grey;
    cursor: pointer;
    margin-right: 20px;
}

.checkboxText{

}

.lable{
    width: 70%;
    text-align: left;
    padding-right: 20px;
}

.infoItemValue {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 1px solid black;
    color: var(--text-primary);
    font-family: Ubuntu-R;
    font-size: 12pt;
    padding: 0 0 6px 0;
}

.lableLow{
    font-size: 14px;
    font-weight: 400;
    width: 60%;
}