.container {
    display: flex;
    width: 100%;
}

.disabled{
    cursor: default !important;
}

.container * {
    box-sizing: border-box;
}

.switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 26px;
    margin-left: auto;
    margin-top: -3px;
}

.switch input {display:none;}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider::before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--color-brand);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--color-brand);
}

input:checked + .slider::before {
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
    background-image: url("../../../../svg/button_on-02.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.slider {
    border-radius: 34px;
}

.slider::before {
    border-radius: 50%;
}